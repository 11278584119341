import { invoke, flowRight, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import withExperiment from '../../hoc/with-experiment';
import withButtonColor from '../../hoc/with-button-color';
import withSettingsColor from '../../hoc/with-settings-color';
import withAuth from '../../hoc/with-auth';
import commentFormSettings from '../../services/comment-form-settings';
import RichContentEditor from '../rich-content-editor-async';
import getCommentTheme from '../rich-content-editor/theme-comment';
import { isContentStateEmpty } from '../../services/content-state-utils';
import { convertContentStateToContent } from '../../services/post-utils';
import { PLUGINS } from '@wix/communities-forum-client-commons/dist/src/constants/plugins-constants';
import ProtectedButton from '../../containers/protected-button';
import { getElementsPositionToRootWindow } from '../../services/get-elements-position';
import { SIDE_TOOLBAR_OFFSET as SIDE_TOOLBAR_OFFSET_DEFAULT } from '../rich-content-editor/constants';
import { SendIcon } from '../icons/send-icon';
import { withFastForm } from '../../../common/components/fast-form';
import { getPost } from '../../selectors/post-selectors';
import { getCurrentUserRemainingPosts } from '../../../common/store/current-user/current-user-selectors';
import { CREATE_COMMENT, CREATE_REPLY } from '../../constants/interactions';
import { REPLY } from '../../constants/form-types';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { EXPERIMENT_COMMENT_RATE_LIMITER } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { scrollBy } from '../../services/scroll-by';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import InputText from '../input-text';
import { COMMENT_LIMIT_REACHED } from '../messages/message-types';

import styles from './comment-bar-rce.scss';
import rceTheme from '../rich-content-editor/theme.scss';
import { createInitialDraftJsContentWithText } from '../../services/create-initial-draft-content-with-text';
import { RCEPlusIcon } from '../icons/rce-plus-icon';

const MOBILE_SIDE_TOOLBAR_OFFSET = { x: 44, y: 0 };

const SIDE_TOOLBAR_OFFSET = {
  ...SIDE_TOOLBAR_OFFSET_DEFAULT,
  mobile: { ios: MOBILE_SIDE_TOOLBAR_OFFSET, android: MOBILE_SIDE_TOOLBAR_OFFSET },
};

const createEditorKey = () => new Date().getTime();

export class CommentBarRce extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorKey: createEditorKey(),
    };
  }

  componentDidMount = () =>
    this.props.scrollIntoView &&
    this.selfElement &&
    getElementsPositionToRootWindow(this.selfElement, { top: true }, y => {
      scrollBy(0, y);
    });

  componentDidUpdate(prevProps) {
    const props = this.props;
    if (prevProps.fastForm.values.content && !props.fastForm.values.content) {
      this.setState({
        editorKey: createEditorKey(),
      });
    }
    if (props.isAuthenticated && !prevProps.isAuthenticated) {
      props.fastForm.resetForm();
    }
  }

  handleContentChange = contentState => {
    this.props.fastForm.changeValue('content')(contentState);

    if (contentState && contentState.getBlockMap) {
      if (!isContentStateEmpty(contentState)) {
        invoke(this.props, 'onChange');
      }
    } else {
      const blocks = contentState.blocks;
      if (blocks[0].text || blocks.length > 1) {
        invoke(this.props, 'onChange');
      }
    }
  };

  handleSubmitButton = () => {
    const {
      isCommentLimiterEnabled,
      remainingPosts,
      showMessage,
      post,
      type,
      fastForm,
    } = this.props;
    const isReply = type === REPLY;
    const isDiscussion = post.postType === DISCUSSION;

    if (isCommentLimiterEnabled && isNumber(remainingPosts) && remainingPosts < 1) {
      showMessage(COMMENT_LIMIT_REACHED, { isReply, isDiscussion });
    } else {
      fastForm.submit();
    }
  };

  render = () => {
    const {
      fastForm,
      buttonColor,
      darkIconColor,
      containerClass,
      scrollIntoView,
      placeholderKey,
      postId,
      parentCommentId,
      forPublicUser,
      type,
      t,
    } = this.props;

    const containerClassName = classNames(
      styles.container,
      containerClass,
      'forum-text-color',
      'comment-bar',
    );
    const buttonClassName = classNames(styles.saveButton, 'comment-bar__send-button');

    return (
      <div
        className={containerClassName}
        data-hook="comment-form"
        ref={r => (this.selfElement = r)}
      >
        <div className={rceTheme.commentInput}>
          <RichContentEditor
            key={this.state.editorKey}
            placeholder={t(placeholderKey)}
            onChange={this.handleContentChange}
            externalModalsEnabled
            initialState={fastForm.values.content || undefined}
            showAdvancedImageSettings={false}
            plugins={[
              PLUGINS.IMAGE,
              PLUGINS.VIDEO,
              PLUGINS.MENTIONS,
              PLUGINS.GIPHY,
              PLUGINS.FILE_UPLOAD,
            ]}
            sideToolbarOffset={SIDE_TOOLBAR_OFFSET}
            themeGetter={getCommentTheme}
            focus={scrollIntoView}
            loadableLoading={() => (
              <Loader
                bubbleClassName={classNames(styles.loaderBubble, 'forum-text-background-color')}
              />
            )}
            compact
            isInline
            origin="comment"
          />
        </div>
        <div style={{ position: 'relative' }}>
          <ProtectedButton
            actionDetails={
              type === REPLY
                ? {
                    action: CREATE_REPLY,
                    args: [postId, parentCommentId, convertContentStateToContent(fastForm.values)],
                  }
                : {
                    action: CREATE_COMMENT,
                    args: [postId, convertContentStateToContent(fastForm.values)],
                  }
            }
            isDisabled={fastForm.isSubmitting || !fastForm.isValid}
          >
            <button
              className={buttonClassName}
              disabled={fastForm.isSubmitting || !fastForm.isValid}
              onClick={forPublicUser(this.handleSubmitButton)}
              data-hook="comment-bar__send-button"
            >
              <SendIcon
                style={{
                  width: '25px',
                  height: '25px',
                  fill: !fastForm.isValid ? darkIconColor : buttonColor,
                }}
              />
            </button>
          </ProtectedButton>
        </div>
      </div>
    );
  };
}

CommentBarRce.propTypes = {
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  t: PropTypes.func,
  invalid: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  size: PropTypes.string,
  buttonColor: PropTypes.string,
  darkIconColor: PropTypes.string,
  containerClass: PropTypes.string,
  placeholderKey: PropTypes.string,
  scrollIntoView: PropTypes.bool,
  postId: PropTypes.string,
  parentCommentId: PropTypes.string,
  type: PropTypes.string,
  remainingPosts: PropTypes.number,
  showMessage: PropTypes.func,
  isCommentLimiterEnabled: PropTypes.bool,
  post: PropTypes.object,
};

CommentBarRce.defaultProps = {
  placeholderKey: 'text-editor.comment-placeholder',
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  showMessage: actions.showMessage,
  remainingPosts: getCurrentUserRemainingPosts(state),
  post: getPost(state, ownProps.postId),
});

export default flowRight(
  withFastForm(commentFormSettings),
  withTranslate,
  withFontClassName,
  withButtonColor,
  withAuth,
  withSettingsColor({
    propName: 'darkIconColor',
    siteColorFallback: 'color-5',
    fallbackColor: 'rgba(51, 51, 51, 1)',
  }),
  connect(mapRuntimeToProps),
  withExperiment({
    isCommentLimiterEnabled: EXPERIMENT_COMMENT_RATE_LIMITER,
  }),
)(CommentBarRce);
