import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { QUESTION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withDividerColor from '../../hoc/with-divider-color';
import { CommentIcon } from '../icons/comment-icon';
import { RepliesIcon } from '../icons/replies-icon';
import styles from './post-page-sidebar-stats.scss';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_NEW_QA_KEYS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { ViewCountIcon } from '../icons/view-count-icon';

const PostPageSidebarStats = ({
  viewCount,
  totalComments,
  totalReplies,
  dividerColor,
  postType,
  isNewQAKeysEnabled,
  isViewsCountEnabled,
  t,
}) => {
  const isQuestionType = postType === QUESTION;
  const commentCount = totalComments && totalComments - (totalReplies || 0);

  return (
    <section className={styles.container} style={{ borderColor: dividerColor }}>
      <div className={styles.iconColumn}>
        {isViewsCountEnabled && (
          <div className={styles.row}>
            <ViewCountIcon className="forum-icon-fill" />
          </div>
        )}
        <div className={styles.row}>
          <CommentIcon className="forum-icon-stroke" />
        </div>
        {isQuestionType && (
          <div className={styles.row}>
            <RepliesIcon className="forum-icon-fill" />
          </div>
        )}
      </div>
      <div className={classNames(styles.column, 'forum-text-color')}>
        {isViewsCountEnabled && (
          <div className={styles.row} data-hook="post-page-sidebar-stats__view-count">
            {t('post-page-sidebar-stats.view-count', { count: viewCount })}
          </div>
        )}
        <div className={styles.row} data-hook="post-page-sidebar-stats__comment-count">
          {t(
            isQuestionType
              ? 'post-page-sidebar-stats.answer-count'
              : 'post-page-sidebar-stats.comment-count',
            { count: isQuestionType ? commentCount : totalComments },
          )}
        </div>
        {isQuestionType && (
          <div className={styles.row} data-hook="post-page-sidebar-stats__reply-count">
            {t(
              isQuestionType && isNewQAKeysEnabled
                ? 'post-page-sidebar-stats.question.reply-count'
                : 'post-page-sidebar-stats.reply-count',
              { count: totalReplies },
            )}
          </div>
        )}
      </div>
    </section>
  );
};

PostPageSidebarStats.propTypes = {
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
  totalReplies: PropTypes.number.isRequired,
  postType: PropTypes.string.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
  t: PropTypes.string.function,
};

export default flowRight(
  withTranslate,
  withDividerColor,
  withExperiment({
    isNewQAKeysEnabled: EXPERIMENT_NEW_QA_KEYS,
  }),
)(PostPageSidebarStats);
