import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { QUESTION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import { APP_TEXT_COLOR_PATH } from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import UserAvatarImage from '../user-avatar-image';
import { AnonymousIcon } from '../icons/anonymous-icon';
import withSettingsColor from '../../hoc/with-settings-color';
import { getCurrentUser } from '../../../common/store/current-user/current-user-selectors';
import styles from './comment-box.scss';

const CommentBox = ({ post, onClick, currentUser, borderColor, t }) => {
  const containerClassName = classNames(styles.container, 'comment-bar');

  const placeholderKey =
    post.postType === QUESTION
      ? 'text-editor.answer-placeholder'
      : 'text-editor.comment-placeholder';

  const handleButtonClick = () => onClick && onClick();

  return (
    <div className={containerClassName} data-hook="comment-bar">
      {currentUser ? <UserAvatarImage className={styles.avatar} user={currentUser} />:<AnonymousIcon className={styles.avatar} />}
      <button
        className={classNames(styles.fakeInput, 'forum-text-color')}
        onClick={handleButtonClick}
        style={{ borderColor }}
      >
        <span className={styles.placeholder}>{t(placeholderKey)}</span>
      </button>
    </div>
  );
};

CommentBox.propTypes = {
  t: PropTypes.func,
  post: PropTypes.object,
  onClick: PropTypes.func,
  borderColor: PropTypes.string,
};

CommentBox.defaultProps = {
  placeholderKey: 'text-editor.comment-placeholder',
};

const mapRuntimeToProps = state => ({
  currentUser: getCurrentUser(state),
});

export default flowRight(
  withTranslate,
  connect(mapRuntimeToProps),
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'borderColor',
    alpha: 0.2,
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
  }),
)(CommentBox);
